import { createModel } from '@rematch/core'
import {
  getFunds,
  getFundType,
  getSubFundType,
  getGeographic,
  getRisk,
  getManagement,
} from '../../services/generaliService';
import type {
  FundsState,
  FundsType,
  FiltersType,
  FiltersTypeGeneric,
  RootModel,
  PaginationType,
} from '../types';
import { FiltersEnum, FiltersArr } from '../enumerables';
import { convertParams } from '../utils';

const PROMISE_INDEX: any = {
  0: FiltersEnum.FUND_TYPE,
  1: FiltersEnum.GEOGRAPHIC,
  2: FiltersEnum.MANAGEMENT,
  3: FiltersEnum.RISK,
  4: FiltersEnum.SUB_FUND_TYPE,
};

const funds = createModel<RootModel>()({
  state: {
    inProgress: true,
    error: false,
    funds: [], // actual table state
    fundsCount: [], // copy of funds filtered by user
    allFunds: [], // all unmodified funds (used with cart)
    filters: {
      esg: {
        data: [ 'selector.esgOptions.no', 'selector.esgOptions.yes' ], 
        error: false
      },
      tradeable: {
        data: [ 'selector.tradeableOptions.no', 'selector.tradeableOptions.yes' ], 
        error: false
      },
    },
    params: {},
  } as FundsState,
  reducers: {
    setParameters(state, payload: string) {
      const params = payload
        ? { ...convertParams(payload), params: payload }
        : { params: null };
      return {
        ...state,
        params: { ...params},
      }
    },
    toggleInProgress(state, payload: boolean = false) {
      return {
        ...state,
        inProgress: payload,
      };
    },
    toggleError(state, payload = false) {
      return {
        ...state,
        error: payload
      };
    },
    setFunds(state, payload: Array<FundsType>) {
      return {
        ...state,
        funds: payload,
        fundsCount: payload,
        allFunds: payload,
      };
    },
    // number of the selector button
    setFundsCount(state, payload: Array<FundsType>) {
      return {
        ...state,
        fundsCount: payload,
      };
    },
    // copy the filtered funds search to the displayable data (button number to table data)
    searchFunds(state) {
      return {
        ...state,
        funds: state.fundsCount,
      };
    },
    setPagination(state, payload: PaginationType) {
      return {
        ...state,
        pagination: payload,
      };
    },
    // set the options to filteer funds. Only once when selector is created
    setFilters(state, payload: FiltersType) {
      const filters = {
        ...state.filters,
        ...payload,
      };

      return {
        ...state,
        filters,
      };
    },
  },
  effects: (dispatch) => ({
    /**
     * Only called when default page starts
     * @param payload filters
     */
    async getFundsAsync(payload, state) {
      dispatch({ type: 'funds/toggleInProgress', payload: true });
      const newPayload = {
        fundsExcluded: state.funds.params?.fundsExcluded,
        fundsIgnored: state.funds.params?.fundsIgnored,
      };
      await getFunds(newPayload)
        .then(res => {
          dispatch({ type: 'funds/setFunds', payload: res.data });
          dispatch({ type: 'funds/setPagination', payload: res?.metadata?.pagination || 0 });

          dispatch({ type: 'funds/toggleInProgress', payload: false });
        })
        .catch(err => {
          dispatch({ type: 'funds/toggleError', payload: true });
          dispatch({ type: 'funds/toggleInProgress', payload: false });

          setTimeout(() => {
            dispatch({ type: 'funds/toggleError', payload: false })
          }, 3000);
        });
    },
    async refreshCountAsync(payload, state) {
      const payloadWithExcluded = {
        ...payload,
        fundsExcluded: state.funds.params?.fundsExcluded,
        fundsIgnored: state.funds.params?.fundsIgnored,
      };
      await getFunds(payloadWithExcluded)
        .then(res => {
          dispatch({ type: 'funds/setFundsCount', payload: res.data });
          dispatch({ type: 'funds/setPagination', payload: res?.metadata?.pagination || 0 });
        })
        .catch(err => {
          dispatch({ type: 'funds/setFundsCount', payload: [] });
          dispatch({ type: 'funds/setPagination', payload: 0 });
        });
    },
    async getFiltersAsync() {
      dispatch({ type: 'funds/toggleInProgress', payload: true });

      const promiseBunch = [];
      promiseBunch.push(getFundType());
      promiseBunch.push(getGeographic());
      promiseBunch.push(getManagement());
      promiseBunch.push(getRisk());
      promiseBunch.push(getSubFundType());


      Promise.allSettled(promiseBunch).then(res => {
        const filtersTypeDefault: FiltersTypeGeneric<any> = {
          data: null,
          error: true,
        };
        const payload: FiltersType | any = {};

        // Promise.allSetled() -> result only iterable (result.value -> error)
        res.forEach((result, i) => {
          const filterType = PROMISE_INDEX[i];
          const filterName: string = FiltersArr[filterType].name;
          payload[filterName] = result.status === "fulfilled"
            ? { ...filtersTypeDefault, data: result.value.data, error: false }
            : { ...filtersTypeDefault };
        })

        dispatch({ type: 'funds/setFilters', payload });
      })
    },
  }),
});

export default funds;
