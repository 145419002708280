import { createModel } from '@rematch/core';
import { revertRiskId, convertParams, weightOverflow } from '../utils';
import type {
  RootModel,
  CartType,
  CartState,
} from '../types';

const funds = createModel<RootModel>()({
  state: {
    cart: [],
    error: [],
    riskId: 1,
    showCart: false,
  } as CartState,
  reducers: {
    setParameters(state, payload: string) {
      const params = payload
        ? { ...convertParams(payload), params: payload }
        : { params: null };
      return {
        ...state,
        ...params,
        showCart: params.showCart,
      }
    },
    toggleError(state, payload = []) {
      return {
        ...state,
        error: payload
      };
    },
    addFundToCart(state, payload: { id: string, riskId: number }) {
      const cart = state.cart.find(elem => elem.id === payload.id)
        ? state.cart
        : [ ...state.cart, { id: payload.id, weight: undefined, riskId: payload.riskId } ];

      return {
        ...state,
        cart,
      };
    },
    removeFundFromCart(state, payload: string) {
      const cart = [ ...state.cart ];
      const elementIndex = cart.findIndex(elem => elem.id === payload);
      elementIndex >= 0 && cart.splice(elementIndex, 1);

      return {
        ...state,
        cart,
      };
    },
    setCart(state, payload: Array<CartType>) {
      return {
        ...state,
        cart: payload,
        error: [],
      };
    },
    setCartWeight(state, payload: { id: string, weight: number }) {
      const cart = [ ...state.cart ];
      const elementIndex = cart.findIndex(elem => elem.id === payload.id);

      if (elementIndex >= 0) {
        cart[elementIndex].weight = payload.weight;
      }

      return {
        ...state,
        cart,
      };
    },
  },
  effects: (dispatch) => ({
    // post event to generate wallet/transfer
    async throwEvent(payload, state) {
      const { cart, portfolioId, riskId: riskState, transId, operId } = state.cart;
      const riskId = revertRiskId(riskState);
      
      window.opener.postMessage({
        id: window.appConfig.EMITTER_ID,
        event: {
          portfolioId,
          riskId,
          transId,
          operId,
          funds: cart.map(elem => ({
            yisin: state.funds?.allFunds?.filter(el => el.id === elem.id)[0]?.isin,
            xdifnu: elem.weight,
          })),
        },
        origin: window.location.origin,
      }, '*');
    },
    /**
     * Add a new fund to the cart after validate the risk overflow
     * @param payload new cart fund
     */
    async setCartWeightAsync(payload: { id: string, weight: number }, state) {
      const { cart, riskId, error } = state.cart;
      const { id, weight } = payload;

      const fund = cart.filter(elem => id === elem.id)[0];
      // same riskId but not this
      const cartByRiskId = cart.filter(elem => (fund?.riskId === elem.riskId) && id !== elem.id);
      const sumWeight = ((cartByRiskId.length ? cartByRiskId.reduce((prev, elem) => ({
        ...prev,
        weight: (prev.weight || 0) + (elem.weight || 0),
      })).weight : 0) || 0) + weight;

      const overflowError = weightOverflow(riskId, fund.riskId, sumWeight);
      const cartPayload = { id, weight };

      if (overflowError !== null) {
        dispatch({ type: 'cart/toggleError', payload: [
          ...error,
          { riskId: fund.riskId, maxWeight: overflowError }
        ]});
        dispatch({ type: 'cart/setCartWeight', payload: cartPayload });
      } else {
        // delete fund.riskId error
        const errorFiltered = error.filter(elem => elem.riskId !== fund.riskId);
        dispatch({ type: 'cart/toggleError', payload: errorFiltered });
        dispatch({ type: 'cart/setCartWeight', payload: cartPayload });
      }
    },
  }),
});

export default funds;
