import { Models } from '@rematch/core'
import funds from '../fundsModel';
import cart from '../cartModel';
import detail from '../detailModel';

export interface RootModel extends Models<RootModel> {
  funds: typeof funds,
  cart: typeof cart,
  detail: typeof detail,
};

export const models: RootModel = { funds, cart, detail };