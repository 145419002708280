import { createModel } from '@rematch/core'
import { getDetails } from '../../services/generaliService';
import type {
  RootModel,
  DetailState,
  DetailType,
} from '../types';

const detail = createModel<RootModel>()({
  state: {
    inProgress: true,
    error: false,
    detail: {},
  } as DetailState,
  reducers: {
    toggleInProgress(state, payload: boolean = false) {
      return {
        ...state,
        inProgress: payload,
      };
    },
    toggleError(state, payload = false) {
      return {
        ...state,
        error: payload
      };
    },
    addDetails(state, payload: DetailType) {
      return {
        ...state,
        detail: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async getDetailAsync(payload: string) { /* payload: boolean, state */
      dispatch({ type: 'detail/toggleInProgress', payload: true });

      await getDetails(payload)
        .then((res: DetailType) => {
          dispatch({ type: 'detail/addDetails', payload: res });
        })
        .catch((err: any) => {
          dispatch({ type: 'detail/toggleError', payload: true });
        });

      dispatch({ type: 'detail/toggleInProgress', payload: false });

      setTimeout(() => {
        dispatch({ type: 'detail/toggleError', payload: false })
      }, 3000);
    },
  }),
});

export default detail;
