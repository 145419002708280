export enum RiskId {
  C = 1,
  c = 1,
  M = 2,
  m = 2,
  D = 3,
  d = 3,
}

export const FiltersArr: Array<{ id: number, name: string }> = [
  { id: 0, name: 'fundType' },
  { id: 1, name: 'geographic' },
  { id: 2, name: 'risk' },
  { id: 3, name: 'management' },
  { id: 4, name: 'text' },
  { id: 5, name: 'esg' },
  { id: 6, name: 'subFundType' },
  { id: 7, name: 'tradeable' },
];

export enum FiltersEnum {
  FUND_TYPE = 0,
  GEOGRAPHIC = 1,
  RISK = 2,
  MANAGEMENT = 3,
  TEXT = 4,
  ESG = 5,
  SUB_FUND_TYPE = 6,
  TRADEABLE = 7,
}

export const maxWeight = 100;
export const midWeight = 25;
export const minWeight = 0;

