import React from 'react'
import { StyledSpinner } from './spinnerStyles';

interface SpinnerType {
  white?: boolean,
}
export default function Spinner({ white }: SpinnerType) {
  return (
    <StyledSpinner white={white} >
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </StyledSpinner>
  )
}
