import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector'; // Install for browser/ls auto language
import es from '../../locales/es/es.json';

// const language = 'i18nextLng'; // uncomment for storing in lo  

// place available languages
const resources = {
  es: { translation: es }
}

// dropdown language selector
// const langAvailables = [
//   { name: 'es', label: 'es' },
// ]

const i18nConfig = {
  resources,
  lng: "es", // remove if LanguageDetector
  fallbackLng: 'es',

  interpolation: {
    escapeValue: false
  }
}

// i18n LanguageDetector config
// const i18nLDConfig = { // uncomment for get default browser/ls language
//   order: ['localStorage', 'navigator'],

//   // keys or params to lookup language from
//   lookupLocalStorage: language,

//   // cache user language on
//   caches: ['localStorage'],
//   excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
// }

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ...i18nConfig,
    // ...i18nLDConfig
  });

// export { langAvailables };

export default i18n;