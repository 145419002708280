import React, { useEffect, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { useDispatch } from 'react-redux'
import { Dispatch } from '../global/store';
import GlobalStyle from '../globalStyle';

import routes from "../routes/routes";
import Spinner from "../common/Spinner";

function App() {
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const payload = urlParams.get('data');
    
    dispatch({ type: 'cart/setParameters', payload });
    dispatch({ type: 'funds/setParameters', payload });
  }, [dispatch])

  return (
    <Router>
      <GlobalStyle />
      
      <Switch>
        {
          routes.map(route => {
            // destructuring for avoid <route.Page />
            const { id, path, exact, Page } = route;

            return (
              <Route path={path} exact={exact} key={id}>
                <Suspense fallback={<Spinner />}>
                  <Page />
                </Suspense>
              </Route>
            )
          })
        }
      </Switch>
    </Router>
  );
}

export default App;
