const color = '#ffffff';
const background = '#c21b17';
const shadow = '#404040';
const shadowOpacity = 'rgba(64, 64, 64, 0.25)';
const headerRow = '#f8f8f8';
const success = '#67c18b';
const fail = '#c21b17';
const hover = '#ddd';
const cartNumber = '#752127';

export const globalColors = {
  color,
};

export const header = {
  background,
  color,
  button: {
    boxShadow: 'rgba(0, 0, 0, 0.23)',
    buttonBorder: color,
    cartNumber,
  },
};

export const modal = {
  color: shadow,
  background: color,
  success,
  fail,
  button: shadow,
};

export const selector = {
  boxShadow: 'rgba(0, 0, 0, 0.25)',
  color: shadow,
  outline: 'rgba(0, 0, 0, 0.25)',
  searchButton: shadow,
  dropdown: headerRow,
  hover,
  selectSeparator: shadowOpacity,
};

export const table = {
  head: shadow,
  body: shadow,
  headRow: headerRow,
  success,
  fail,
  boxShadow: 'rgba(175, 175, 175, 0.5)',
  input: headerRow,
};

export const details = {
  color: shadow,
  aside: headerRow,
  borderBottom: shadowOpacity,
  lines: {
    first: '#f2634a',
    second: '#851614',
    third: '#c5271b',
  },
};

export const tooltip = {
  background: shadow,
  color,
};

export const spinner = {
  background,
  alternative: 'white',
};

export const chart = {
  lines: shadow,
  bar: fail,
  points: shadow,
};

export const evolution = {
  fund: fail,
  benchmark: 'black',
  lines: shadow,
};

export const distribution = {
  bars: fail,
  color: shadow,
};

export const esgRisk = {
  veryBad: '#b4656f',
  bad: '#ee8491',
  regular: '#ffce7a',
  good: '#a8e6ca',
  best: '#83c1b3',
};
