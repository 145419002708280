import { RiskId, midWeight, minWeight } from '../global/enumerables';

/**
 * Risk id from url to internal handling
 * 
 * @param value 
 * @returns Internal handling number
 */
export function convertRiskId(value: string) {
  switch (value) {
    case 'C':
    case 'c':
      return RiskId.C;
    case 'M':
    case 'm':
      return RiskId.M;
    case 'D':
    case 'd':
      return RiskId.D;
    default:
      return RiskId.C;
  }
}

/**
 * Risk id from internal handling to postMessage
 * 
 * @param value 
 * @returns postMessage string
 */
export function revertRiskId(value: number = RiskId.C) {
  switch (value) {
    case RiskId.C:
      return 'C';
    case RiskId.M:
      return 'M';
    case RiskId.D:
      return 'D';
    default:
      return 'C';
  }
}

/**
 * Convert base64 param to js object and convert riskId
 * 
 * @param params base64 string
 * @returns converted object and custom riskId
 */
export function convertParams(params: string) {
  let decoded: any; 
  try {
    decoded = params ? JSON.parse(window.atob(params)) : { riskId: null };
  } catch {
    decoded = undefined;
  }

  const result = {
    ...decoded,
    showCart: false,
  };

  // convert to boolean
  if (decoded?.singleSelection) {
    result.singleSelection = decoded?.singleSelection === "true" || decoded?.singleSelection === true || false;
  }

  if (decoded?.riskId) {
    result.riskId = convertRiskId(decoded?.riskId);
    result.showCart = true;
  }

  if (decoded?.fundsExcluded) {
    delete result.fundsExcluded;
    if (result.showCart === true) {
      result.fundsIgnored = decoded?.fundsExcluded?.map((x: any) => x.ISIN).join(',');
    } else {
      result.fundsExcluded = decoded?.fundsExcluded?.map((x: any) => x.ISIN).join(',');
    }
  }

  if (decoded?.fundsNoVisible) {
    if (!result.fundsIgnored) {
      result.fundsIgnored = '';
    } else {
      result.fundsIgnored += ',';
    }
    result.fundsIgnored += decoded?.fundsNoVisible?.map((x: any) => x.ISIN).join(',');
  }

  return result;
}

/**
 * Compares the sum of the weights per risk and detects overflow
 * 
 * @param riskId 
 * @param fundRiskId 
 * @param sumWeight 
 * @returns max weight in a risk id or null
 */
export function weightOverflow(
  riskId: number | null | undefined,
  fundRiskId: number,
  sumWeight: number,
) {
  // see RiskChart to understand the weight balance
  if (
    riskId === 1
      && fundRiskId === 2
      && sumWeight > midWeight
  ) return midWeight;
  if (
    riskId === 2
      && fundRiskId === 3
      && sumWeight > minWeight
  ) return minWeight;
  if (
    riskId === 1
      && fundRiskId === 3
      && sumWeight > 0
  ) return 0;

  return null;
}

/**
 * Convert a number (between 0 and 1) to a percentage
 * @param perc number to convert
 * @returns 
 */
export function localePercent(perc: number) {
  return `${(perc * 100).toLocaleString('es-ES', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })} %`;
}

/**
 * Convert a number to a currency format
 * @param num number to convert
 * @param currency currency format (EUR by default)
 * @returns 
 */
export function localeCurrency(num: number, currency?: string | undefined) {
  return num.toLocaleString('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency: currency || 'EUR',
  });
}
