import { ComponentType, lazy } from 'react';

// Routes import
const Default = lazy(() => import('../pages/Default'));
const Details = lazy(() => import('../pages/Details'));

type routeType = {
  id: string,
  path: string,
  label?: string,
  Page: ComponentType,
  exact: boolean,
  private?: boolean,
};

const routes: Array<routeType> = [
  {
    id: 'HOME',
    path: '/',
    label: 'routes.home',
    Page: Default,
    exact: true,
  },
  {
    id: 'DETAILS',
    path: '/:id',
    Page: Details,
    exact: true,
  },
  // {
  //   id: 'ERROR',
  //   path: 'error',
  //   label: 'routes.error',
  //   Page: Error,
  //   exact: true,
  // }
  // {
  //   id: '404',
  //   path: '*',
  //   label: 'routes.404',
  //   // page: Lazy404,
  //   Page: () => <h1>Ops! Something went wrong</h1>,
  //   exact: false,
  // }
];

export default routes;
