import axios from 'axios';

const instance = axios.create();
const context = `${window.appConfig.API}/generali/v1/public`;

instance.interceptors.request.use(async config => {
  config.headers = {
   'Content-Type': 'application/json',
  };

  return config;
}, error => {
  Promise.reject(error)
});

instance.interceptors.response.use(response => {
  return response
}, async function (error) {
  const originalRequest = error.config;

  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;

    return instance(originalRequest);
  }
  return Promise.reject(error);
});

function trim(value: string): string {
  return value.replaceAll(' ', '+');
}

export function getFunds(filters: any) {
  let url = `${context}/instruments/search?`;
  const urlParams = [];
  filters?.text && (urlParams.push(`q=${encodeURIComponent(trim(filters.text))}`));
  filters?.fundType && (urlParams.push(`productTypeCode=${trim(filters.fundType)}`));
  filters?.subFundType && (urlParams.push(`subProductTypeCode=${trim(filters.subFundType)}`));
  filters?.geographic && (urlParams.push(`regionCode=${trim(filters.geographic)}`));
  filters?.management && (urlParams.push(`fundHouse=${encodeURIComponent(trim(filters.management))}`));
  filters?.risk && (urlParams.push(`riskId=${filters.risk}`));
  (filters?.esg === true || filters?.esg === false) && (urlParams.push(`esg=${filters.esg}`)); // change to nulish (??)
  (filters?.tradeable === true || filters?.tradeable === false) && (urlParams.push(`tradeable=${filters.tradeable}`)); // change to nulish (??)
  filters?.fundsExcluded && (urlParams.push(`fundsExcluded=${filters.fundsExcluded}`))
  filters?.fundsIgnored && (urlParams.push(`fundsIgnored=${filters.fundsIgnored}`))
  url = `${url}${urlParams.join('&')}`;
  return instance.get(url).then(res => res.data);
}

export function getFundType() {
  return instance.get(`${context}/masterdatas/producttypes/search`).then(res => res.data);
}

export function getSubFundType() {
  return instance.get(`${context}/masterdatas/subproducttypes/search`).then(res => res.data);
}

export function getGeographic() {
  return instance.get(`${context}/masterdatas/geographicareas/search`).then(res => res.data);
}

export function getManagement() {
  return instance.get(`${context}/masterdatas/fundhouses/search`).then(res => res.data);
}

export function getRisk() {
  return instance.get(`${context}/masterdatas/riskprofiles/search`).then(res => res.data);
}

const details = new Map([]);

export function getDetails(id: string): Promise<any> {
  return new Promise((resolve, reject) => {
    if (details.has(id)) {
      resolve(details.get(id));
    }
  
    return instance.get(`${context}/analysis/instruments/${id}`)
      .then(res => {
        details.set(id, res.data)
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
