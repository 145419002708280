import { createGlobalStyle } from 'styled-components';
import { globalColors } from './colors';

export const breakpoints = {
  xsmall: '600px',
  small: '768px',
  medium: '992px',
  large: '1200px',
};

export const fontSize = {
  tiny: '9px',
  small: '13px',
  regular: '14px',
  big: '16px',
  xbig: '18px',
  title: '24px',
};
 
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: HelveticaNeue, Helvetica, Sans-Serif;
    color: ${globalColors.color};
  }
`; 

export default GlobalStyle;